import moment from "moment";

export default {
  formattedDate: function(date) {
    moment.locale("pl");
    return moment(date).format("YYYY-MM-DD, HH:mm:ss");
  },
  validateNegative(data) {
    var isNegative;
    const negativeRegex = /^[-]{1}[0-9]{1}/;
    isNegative = negativeRegex.test(data);
    if (isNegative) {
      return `(${data.slice(1)})`;
    } else {
      return data;
    }
  },
  numbersWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
  validateCapital(data) {
    const capitalRegex = /^[A-Z]{1}[-]{1}/;
    return capitalRegex.test(data);
  },
  validateSmall(data) {
    const smallRegex = /^[a-z]{1}[)]{1}/;
    return smallRegex.test(data);
  },
  validateRoman(data) {
    const romanRegex = /^(IX|IV|V?I{0,3})[.]{1}/;
    return romanRegex.test(data);
  },
  validateNumber(data) {
    const numberRegex = /^[0-9]|[1-9]{1}[.]{1}/;
    return numberRegex.test(data);
  }
};
